<template>

  <template v-if="isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <FormSelect
      ref="focusElement"
      :isSearchable="true"
      :formName="formName"
      fieldName="patchTemplateId"
    />

    <form @submit="submit" :class=" { 'show': selectedPatchTemplateOption }">
      <LoadingOverlay type="light" v-if="form.isLoading" />
      <FormSubmitErrors :formName="formName" />
      <div class="flex space-x-8">
        <FormSelect :formName="formName" fieldName="triggerId" :isSearchable="true" />
        <FormSelect :formName="formName" fieldName="actionId" :isSearchable="true" />
      </div>
      <div class="flex space-x-8">
        <FormSelect :formName="formName" fieldName="networkId" :isSearchable="true" />
        <FormSelect :formName="formName" fieldName="contractId" :isSearchable="true" type="rich" />
      </div>
      <FormInput :formName="formName" fieldName="name" />
      <FormInput :formName="formName" fieldName="slug">
        <template #label-extra-content>
          <Tooltip class="self-center">
            <template #trigger>
              <InfoIcon />
            </template>
            <p>
              This should typically stay as the computed value.
            </p>
            <p>
              If the computed value looks weird, ask someone with database
              access to edit it for you.
            </p>
          </Tooltip>
        </template>
      </FormInput>
      <FormInput :formName="formName" fieldName="deeplinkUrl">
        <template #label-extra-content>
          <Tooltip class="self-center">
            <template #trigger>
              <InfoIcon />
            </template>
            <p>
              This is calculated programatically based on the slug and can not
              be edited. This field is included here for easy copy / pasting.
            </p>
            <p>
              Please note that if the slug value changes, any existing deeplinks
              with the old slug will no longer work. However, a change can be
              made in the useActivatePatchTemplate composable to support the old
              deeplink if it's already been used in emails or blog posts.
            </p>
          </Tooltip>
        </template>
      </FormInput>
      <FormInput :formName="formName" fieldName="description" />
      <button type="submit" :disabled="form.isLoading || !isFormValid">{{ isUpdating ? 'Update' : 'Submit' }}</button>
    </form>

  </template>

</template>

<script>

  import { markRaw } from 'vue'
  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useFilters from '@/composables/useFilters'

  import FormInput from '@/components/forms/FormInput.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'

  import AddIcon from '@/assets/icons/add.svg'
  import InfoIcon from '@/assets/icons/info.svg'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    components: {
      Tooltip,
      InfoIcon,
      FormInput,
      FormSelect,
      LoadingIcon,
      LoadingOverlay,
      FormSubmitErrors,
    },
    setup() {

      // data
      const formName = 'adminPatchTemplateForm'

      const { paramCase } = useFilters()
      const { form, isFormValid, focusElement } = useForm({ formName })

      return {
        form,
        formName,
        paramCase,
        isFormValid,
        focusElement,
      }

    },
    computed: {
      ...mapState('app', [
        'patchTemplates',
        'triggerOptions',
        'actionOptions',
        'networkOptions',
        'isBootstrapDataLoading',
        'triggerNetworkOptionsSlugMap',
        'networkContractOptionsByTypeSlugMap',
      ]),
      formFields() {
        return this.form.fields
      },
      isUpdating() {
        return this.selectedPatchTemplateOption && this.selectedPatchTemplateOption.value !== 'add-new-patch-template'
      },
      selectedPatchTemplateOption() {

        if (!this.formFields.patchTemplateId.value) return null

        const selectedOption = this.formFields.patchTemplateId.options.find((option) => {
          return option.value === this.formFields.patchTemplateId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedTriggerOption() {

        if (!this.formFields.triggerId.value) return null

        const selectedOption = this.formFields.triggerId.options.find((option) => {
          return option.value === this.formFields.triggerId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
      selectedNetworkOption() {

        if (!this.formFields.networkId.value) return null

        const selectedOption = this.formFields.networkId.options.find((option) => {
          return option.value === this.formFields.networkId.value
        })

        if (!selectedOption) return null

        return selectedOption

      },
    },
    watch: {
      'formFields.name.value': function nameValue(newValue, oldValue) {

        // @NOTE: don't allow updating a slug once it's been set on initial
        //  creation
        if (this.isUpdating) return

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'slug',
          formName: this.formName,
          // @NOTE: for some reason this paramCase method converts "NFTs" to
          //  "nf-ts" so we'll just fix that manually here...
          newValue: this.paramCase(newValue).replace('nf-ts', 'nfts'),
        })

      },
      'formFields.slug.value': function nameValue(newValue, oldValue) {
        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'deeplinkUrl',
          formName: this.formName,
          newValue: `${process.env.VUE_APP_DISPATCH_URL}/explore/${this.formFields.slug.value}`,
        })
      },
      selectedPatchTemplateOption(newValue, oldValue) {

        if (!newValue) return

        this.$store.commit('forms/RESET_FORM', this.formName)

        this.$store.commit('forms/SET_FIELD_VALUE', {
          formName: this.formName,
          fieldName: 'patchTemplateId',
          newValue: newValue.value,
        })

        if (newValue.value === 'add-new-patch-template') return

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'triggerId',
          formName: this.formName,
          newValue: newValue.apiRecord.trigger.id,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'actionId',
          formName: this.formName,
          newValue: newValue.apiRecord.action.id,
        })

        if (newValue.apiRecord.network) {
          this.$nextTick(() => {

            this.$store.commit('forms/SET_FIELD_VALUE', {
              fieldName: 'networkId',
              formName: this.formName,
              newValue: newValue.apiRecord.network.id,
            })

            if (newValue.apiRecord.contract) {
              this.$nextTick(() => {
                this.$store.commit('forms/SET_FIELD_VALUE', {
                  fieldName: 'contractId',
                  formName: this.formName,
                  newValue: newValue.apiRecord.contract.id,
                })
              })
            }

          })
        }

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'name',
          formName: this.formName,
          newValue: newValue.apiRecord.name,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'slug',
          formName: this.formName,
          newValue: newValue.apiRecord.slug,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'description',
          formName: this.formName,
          newValue: newValue.apiRecord.description,
        })

      },
      selectedTriggerOption(newValue, oldValue) {

        if (!newValue) return

        const newNetworkOptions = this.triggerNetworkOptionsSlugMap[newValue.apiRecord.slug]
        const newNetworkOptionValue = newNetworkOptions.length === 1
          ? newNetworkOptions[0].value
          : ''

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'networkId',
          formName: this.formName,
        })

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'contractId',
          formName: this.formName,
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          fieldName: 'networkId',
          formName: this.formName,
          newOptions: newNetworkOptions,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: newNetworkOptions.length === 1,
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'networkId',
          formName: this.formName,
          newValue: newNetworkOptionValue,
        })

      },
      selectedNetworkOption(newValue, oldValue) {

        if (!newValue) return

        let newContractOptions = null

        switch (this.selectedTriggerOption.apiRecord.type) {

          case 'balance-change':
            newContractOptions = []
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug].base || [])
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-20'] || [])
            break

          case 'nft':
            newContractOptions = []
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-721'] || [])
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-1155'] || [])
            break

          case 'user-contract': {
            newContractOptions = []
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-20'] || [])
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-721'] || [])
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug]['erc-1155'] || [])
              .concat(this.networkContractOptionsByTypeSlugMap[newValue.apiRecord.slug].other || [])
            break
          }

          default:
            return

        }

        const newContractOptionValue = newContractOptions.length === 1
          ? newContractOptions[0].value
          : ''

        this.$store.commit('forms/RESET_FORM_FIELD', {
          fieldName: 'contractId',
          formName: this.formName,
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          fieldName: 'contractId',
          formName: this.formName,
          newOptions: newContractOptions,
        })

        this.$store.commit('forms/SET_FIELD_DISABLED', {
          fieldName: 'contractId',
          formName: this.formName,
          newValue: (
            newContractOptions.length === 1
            || this.selectedTriggerOption.apiRecord.type === 'v2-dex'
            || this.selectedTriggerOption.apiRecord.type === 'v3-dex'
          ),
        })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'contractId',
          formName: this.formName,
          newValue: newContractOptionValue,
        })

      },
    },
    created() {

      this.updatePatchTemplateOptions()

      this.$store.commit('forms/SET_FIELD_OPTIONS', {
        formName: this.formName,
        fieldName: 'triggerId',
        newOptions: this.triggerOptions,
      })

      this.$store.commit('forms/SET_FIELD_OPTIONS', {
        formName: this.formName,
        fieldName: 'actionId',
        newOptions: this.actionOptions,
      })

    },
    methods: {
      updatePatchTemplateOptions() {

        const newPatchTemplateOptions = this.patchTemplates.map((patchTemplate) => {
          return {
            value: patchTemplate.id,
            apiRecord: patchTemplate,
            label: patchTemplate.name,
            description: patchTemplate.description,
            secondaryIconUrl: patchTemplate.action.iconUrl,
            iconUrl: patchTemplate.contract ? patchTemplate.contract.iconUrl : patchTemplate.trigger.iconUrl,
          }
        })

        newPatchTemplateOptions.unshift({
          value: 'add-new-patch-template',
          label: 'Add new Patch Template',
          icon: markRaw(AddIcon),
        })

        this.$store.commit('forms/SET_FIELD_OPTIONS', {
          formName: this.formName,
          fieldName: 'patchTemplateId',
          newOptions: newPatchTemplateOptions,
        })

      },
      submit($event) {

        if ($event) $event.preventDefault()

        this.$store.commit('forms/SET_SUBMIT_METHOD', {
          newSubmitMethod: this.isUpdating ? 'put' : 'post',
          formName: this.formName,
        })

        this.$store.commit('forms/SET_SUBMIT_ENDPOINT', {
          newSubmitEndpoint: this.isUpdating ? `/patches/templates/${this.selectedPatchTemplateOption.apiRecord.id}` : '/patches/templates',
          formName: this.formName,
        })

        return this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then(() => {

            // @NOTE: we save isUpdating here since restting the form will reset
            //  the flag to false
            const { isUpdating } = this

            this.$store.commit('forms/RESET_FORM', this.formName)

            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Patch Template ${isUpdating ? 'updated' : 'created'}!`,
              type: 'success',
            })

            if (!isUpdating) {
              this.$store.commit('forms/SET_FIELD_VALUE', {
                formName: this.formName,
                fieldName: 'patchTemplateId',
                newValue: 'add-new-patch-template',
              })
            }

            return this.$store.dispatch('app/REFRESH_PATCH_TEMPLATES')
              .then(() => {
                this.updatePatchTemplateOptions()
              })

          })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: `Could not ${this.isUpdating ? 'update' : 'create'} Patch Template: ${error.message}`,
              type: 'error',
            })
          })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

  form
    @apply h-0
    @apply mt-8
    @apply relative
    @apply opacity-0
    @apply transition-opacity
    @apply pointer-events-none

    &.show
      @apply h-auto
      @apply opacity-100
      @apply pointer-events-auto

</style>
